.cancel-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .cancel-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cancel-popup-box {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 450px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    transition: all 0.3s ease-in-out;
  }
  
  .cancel-popup-box-expanded {
    padding-bottom: 40px;
  }
  
  .cancel-popup-box h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  
  .cancel-popup-box p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .cancel-reason-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .cancel-reason-button {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .cancel-reason-selected {
    background-color: #ff6347;
    color: #fff;
    border: 1px solid #ff6347;
  }
  
  .cancel-reason-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .cancel-other-reason {
    margin-bottom: 20px;
  }
  
  .cancel-other-textarea {
    width: 415px;
    height: 159px;
    padding: 16px;
    font-size: 1em;
    border-radius: 16px 0px 0px 0px;
    border: 1px solid #ccc;
    opacity: 1;
  }
  
  .cancel-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .cancel-cancel-button,
  .cancel-submit-button {
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1em;
    height: 48px;
    width: 200px;
  }
  
  .cancel-cancel-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .cancel-submit-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .cancel-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .cancel-submit-button:hover {
    opacity: 0.8;
  }
  