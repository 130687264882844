.raise-open-popup-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  margin: 20px;
}

.raise-open-popup-button:hover {
  background-color: #0056b3;
}

.raise-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.raise-popup-box {
  background: #fff;
  border-radius: 24px;
  padding: 20px;
  width: 463px;
  height: 207px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.raise-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.raise-popup-box h2 {
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
}
.raise-popup-content{
  display: inline-flex;
  justify-content: space-evenly;
}

.view-faqs-link {
  font-size: 0.9em;
  color: #007bff;
  text-decoration: none;
  padding-left: 85px;
}

.view-faqs-link:hover {
  text-decoration: underline;
}

.raise-popup-box p {
  margin-bottom: 20px;
  font-size: 1em;
  color: #666;
}

.raise-popup-buttons {
  display: flex;
  justify-content: space-between;
}

.raise-no-button,
.raise-yes-button {
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1em;
  height: 48px;
  width: 200px;
}

.raise-no-button {
  background-color: #fff;
  border: 2px solid #ff6347;
  color: #ff6347;
}

.raise-yes-button {
  background-color: #ff6347;
  color: #fff;
}

.raise-no-button:hover {
  background-color: #ff6347;
  color: #fff;
}

.raise-yes-button:hover {
  opacity: 0.8;
}
