.experience-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .experience-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .experience-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .experience-popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    width: 463px;
    height: 563px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .experience-popup-box h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;

  }
  
  .experience-rating {
    margin-bottom: 20px;
    gap: 20px;
    
  }
  
  .experience-star {
    font-size: 2em;
    color: #ccc;
    cursor: pointer;
    padding:10px;
    align-items: center;
   
  }
  
  .experience-star-selected {
    color: #00be33;
  }
  
  .experience-event-details {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    text-align: left;
    border: 1px solid #EFEFEF;
  }
  
  .event-info {
    display: flex;
    align-items: center;
  }
  
  .event-logo {
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .event-text {
    flex-grow: 1;
  }
  
  .event-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .event-tag {
    background-color: #ffc107;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8em;
    margin-left: 8px;
  }
  
  .event-date,
  .event-request-number {
    font-size: 0.9em;
    color: #666;
  }
  
  .experience-review h3 {
    text-align: left;
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 5px;
  }
  
  .review-textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .experience-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .experience-cancel-button,
  .experience-submit-button {
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    width: 200px;
    height: 48px;
  }
  
  .experience-cancel-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .experience-submit-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .experience-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .experience-submit-button:hover {
    opacity: 0.8;
  }
  