.transport-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .transport-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .transport-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .transport-popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    width: 570px;
    height: 354px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .transport-popup-box h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 900;
    line-height: 20px;
  }
  
  .transport-popup-box p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .transport-selection-grid {
    display: inline-flex;
    flex-direction: row;
    gap:40px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
  }
  
  .transport-option-button {
    width: 93px;
    height: 114px;
    padding: 20px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .transport-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .transport-mode-name {
    font-size: 1em;
  }
  
  .transport-option-selected {
    background-color: #ff6347;
    color: #fff;
    border: 1px solid #ff6347;
  }
  
  .transport-option-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .transport-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .transport-cancel-button,
  .transport-continue-button {
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    width: 200px;
    height: 48px;
  }
  
  .transport-cancel-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .transport-continue-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .transport-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .transport-continue-button:hover {
    opacity: 0.8;
  }
  