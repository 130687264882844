@import url("https://fonts.googleapis.com/css?family=Mulish:300,400,600,700");

$fontFamily: Mulish;
$primarycolor: #fc5a47;

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: $fontFamily;
}

.font-family {
  font-family: $fontFamily !important;
}

.primary-color {
  color: #fc5a47 !important;
}
.color-indigo {
  color: #96ade1;
}
.primary-bg-color {
  background-color: #fc5a47 !important;
}

.whitecolor {
  color: white !important;
}

.greyhr {
  opacity: 0.2;
  border-top: 1px solid #757575;
}
.greycolor {
  color: #757575;
}
.text-grey {
  color: #707070bd;
}

.restaurant_landingPage {
  background-color: #000000ab;
  height: 50vh;
}

.restaurant_card_main {
  //height: 60vh;
  overflow-y: scroll;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.restaurant_title_card {
  margin: 40px 10px 5px 10px;
  border: 0.5px solid #b5b3b359;
  border-radius: 25px;
  box-shadow: 0px 3px 6px #00000029;
}
.resturant_card_image {
  height: 95px;
  width: 95px;
  border-radius: 15px;
  align-items: center;
}
.star_rating {
  height: 12px;
  width: 12px;
}
.direction_img {
  height: 15px;
  width: 15px;
}
.first_layer {
  padding: 0px 20px 0px;
}
.hygiene {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: #f7f7f7;
  margin-top: 10px;
}
.resturant_cusine_color {
  color: #707070;
}
.star_color {
  color: #9e9e9e;
}

.shadow {
  box-shadow: 0px 3px 13px #2121210f;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-even {
  display: flex;
  justify-content: space-evenly;
}
.services {
  display: flex;
  overflow-x: scroll;
}
.box-shadow-down {
  box-shadow: 0px 3px 6px #00000029;
}

.menu-card {
  margin: 5px 10px 30px 10px;
  padding: 10px;
  border: 1px solid #b5b3b359;
  border-radius: 5px;
  max-width: 580px;
}

.menu-card-large {
  margin: 5px 10px 30px 10px;
  padding: 0 0 0 10px 0;
  border: 0.5px solid #b5b3b359;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.viewmenucard {
  border: 0.5px solid #b5b3b359;
  border-radius: 20px 20px 0 0;
  box-shadow: -4px -3px 10px #00000029;
}

.services-images-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.8rem;
  padding: 10px;
  width: 60px;
  height: 60px;
}
.bottom-features {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
}
.merchant-tag-card {
  position: absolute;
  top: -10px;
  left: 20px;
  border-radius: 12px;
}

.selected-box {
  border: 2px solid #fc5a47;
  border-radius: 5px;
}

.selected-rounded {
  border: 2px solid #fc5a47;
  border-radius: 50%;
}

.selected-indigo {
  border: 2px solid #96ade1;
  border-radius: 50%;
}

.nowrap {
  white-space: nowrap;
}

.mainPage {
  // height: 100%;
  background-color: #212121;
  max-width: 480px;
  border: 2px solid red;
}
.restaurant_card {
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  margin: auto;
  position: relative;
  top: 40vh;
  height: 60vh;
  // overflow-y: auto;
}

.title_card {
  margin-top: 10px;
  border: 1px solid #b5b3b3;
  border-radius: 20px;
}

.search {
  position: relative;
  .input-group-left {
    border: none;
    background-color: unset;
    position: absolute;
    padding-left: 0.5rem;
  }
  .input-group-right {
    border: none;
    background-color: unset;
    position: absolute;
    padding-right: 0.5rem;
    right: 0;
    color: #7c7878;
  }
  #search {
    padding-left: 2.5rem;
    border-radius: 40px;
  }
  #search:focus {
    border-color: #f96b5624;
    box-shadow: 0 1px 1px #f96b5624 inset, 0 0 8px #f96b5624;
    outline: 0 none;
  }
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}

.rounndborder {
  border: 0.5px solid #9e9e9e94;
  padding: 5px;
  border-radius: 50%;
}
.indigodot {
  height: 5px;
  width: 5px;
  background-color: #9d83ff;
  border-radius: 50%;
  display: inline-block;
}

.expcard-headingsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 16px 16px;
  background-color: #00000075;
}

#read-more-text .display-text-group .displayed-text {
  .read-more-button {
    color: #ee3a23;
  }
}
.cart-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.amealioactive {
  color: #ee3a23;
  text-decoration: underline;
  font-weight: bold;
}

.floatingBtn {
  position: fixed;
  bottom: 12%;
  left: 34%;
  z-index: 10;
  @media screen and (min-width: 1024px) {
    bottom: 10%;
    left: 48%;
  }
}

.border-radius-call {
  border-radius: 36px 36px 0 0 !important;
}
.pr-0 {
  padding-right: 0rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.select-input {
  border-radius: 10px;
  box-shadow: 0px 2px 4px #0000001c;
}

.input div {
  border-radius: 10px;
  box-shadow: 0px 2px 4px #0000001c;
}

.input:focus,
.select-input:focus {
  border-color: white !important;
  box-shadow: 0px 2px 4px #0000001c !important;
  border: 1px solid #ced4da;
}

.send-otp-input-filed {
  width: 100% !important;
  max-width: 40px;
  height: 40px;
  font-size: 24px;
  font-family: Roboto-Bold;
  border-radius: 5px;
  border: 1.5px solid #9e9e9e;
}
.send-otp-input-filed:focus {
  outline: none;
}
//buttons //

.enRadioWithText {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 1px solid #000;

    @media screen and (max-width: 1366px) {
      height: 24px;
      width: 24px;
      border-radius: 12px;
      background-color: #f7f7f7;
      margin-top: 10px;
    }
    .resturant_cusine_color {
      color: #707070;
    }
    .star_color {
      color: #9e9e9e;
    }
    .shadow {
      box-shadow: 0px 3px 13px #2121210f;
    }

    .flexCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flex-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .services {
      display: flex;
      overflow-x: scroll;
    }
    .box-shadow-down {
      box-shadow: 0px 3px 6px #00000029;
    }

    .menu-card {
      margin: 5px 10px 30px 10px;
      padding: 10px;
      border: 0.5px solid #b5b3b359;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
    }

    .menu-card-large {
      margin: 5px 10px 30px 10px;
      padding: 0 0 0 10px 0;
      border: 0.5px solid #b5b3b359;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
    }

    .viewmenucard {
      border: 0.5px solid #b5b3b359;
      border-radius: 20px 20px 0 0;
      box-shadow: -4px -3px 10px #00000029;
    }

    .services-images-inside {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.8rem;
      padding: 10px;
      width: 60px;
      height: 60px;
    }
    .bottom-features {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px auto;
    }
    .merchant-tag-card {
      position: absolute;
      top: -10px;
      left: 20px;
      border-radius: 12px;
    }

    .selected-box {
      border: 2px solid #fc5a47;
      border-radius: 5px;
    }

    .selected-rounded {
      border: 2px solid #fc5a47;
      border-radius: 50%;
    }

    .nowrap {
      white-space: nowrap;
    }

    .mainPage {
      // height: 100%;
      background-color: #212121;
      max-width: 480px;
      border: 2px solid red;
    }
    .restaurant_card {
      font-size: 20px;
      background-color: #ffffff;
      border-radius: 20px 20px 0 0;
      margin: auto;
      position: relative;
      top: 40vh;
      height: 60vh;
      // overflow-y: auto;
    }

    .title_card {
      margin-top: 10px;
      border: 1px solid #b5b3b3;
      border-radius: 20px;
    }

    .search {
      position: relative;
      .input-group-left {
        border: none;
        background-color: unset;
        position: absolute;
        padding-left: 0.5rem;
      }
      .input-group-right {
        border: none;
        background-color: unset;
        position: absolute;
        padding-right: 0.5rem;
        right: 0;
        color: #7c7878;
      }
      #search {
        padding-left: 2.5rem;
        border-radius: 40px;
      }
      #search:focus {
        border-color: #f96b5624;
        box-shadow: 0 1px 1px #f96b5624 inset, 0 0 8px #f96b5624;
        outline: 0 none;
      }
    }

    a {
      text-decoration: none !important;
      color: inherit !important;
    }

    .mr-1 {
      margin-right: 0.25rem !important;
    }
    .ml-1 {
      margin-left: 0.25rem !important;
    }
    .mt-1 {
      margin-top: 0.25rem !important;
    }
    .mb-1 {
      margin-bottom: 0.25rem !important;
    }

    .mr-2 {
      margin-right: 0.5rem !important;
    }
    .ml-2 {
      margin-left: 0.5rem !important;
    }
    .mt-2 {
      margin-top: 0.5rem !important;
    }
    .mb-2 {
      margin-bottom: 0.5rem !important;
    }

    .mr-3 {
      margin-right: 0.75rem !important;
    }
    .ml-3 {
      margin-left: 0.75rem !important;
    }
    .mt-3 {
      margin-top: 0.75rem !important;
    }
    .mb-3 {
      margin-bottom: 0.75rem !important;
    }

    .mr-4 {
      margin-right: 1rem !important;
    }
    .ml-4 {
      margin-left: 1rem !important;
    }
    .mt-4 {
      margin-top: 1rem !important;
    }
    .mb-4 {
      margin-bottom: 1rem !important;
    }

    .rounndborder {
      border: 0.5px solid #9e9e9e94;
      padding: 5px;
      border-radius: 50%;
    }
    .indigodot {
      height: 5px;
      width: 5px;
      background-color: #9d83ff;
      border-radius: 50%;
      display: inline-block;
    }

    .expcard-headingsection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      border-radius: 0 0 16px 16px;
      background-color: #00000075;
    }

    #read-more-text .display-text-group .displayed-text {
      .read-more-button {
        color: #ee3a23;
      }
    }

    .hidescrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hidescrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .amealioactive {
      color: #ee3a23;
      text-decoration: underline;
      font-weight: bold;
    }

    .floatingBtn {
      position: fixed;
      bottom: 12%;
      left: 40%;
      z-index: 10;
      @media screen and (min-width: 1024px) {
        bottom: 10%;
        left: 48%;
      }
    }

    .border-radius-call {
      border-radius: 36px 36px 0 0;
    }
    .pr-0 {
      padding-right: 0rem !important;
    }
    .pr-1 {
      padding-right: 0.25rem !important;
    }
    .pl-1 {
      padding-left: 0.25rem !important;
    }
    .pt-1 {
      padding-top: 0.25rem !important;
    }
    .pb-1 {
      padding-bottom: 0.25rem !important;
    }

    .pr-2 {
      padding-right: 0.5rem !important;
    }
    .pl-2 {
      padding-left: 0.5rem !important;
    }
    .pt-2 {
      padding-top: 0.5rem !important;
    }
    .pb-2 {
      padding-bottom: 0.5rem !important;
    }

    .pr-3 {
      padding-right: 0.75rem !important;
    }
    .pl-3 {
      padding-left: 0.75rem !important;
    }
    .pt-3 {
      padding-top: 0.75rem !important;
    }
    .pb-3 {
      padding-bottom: 0.75rem !important;
    }

    .pr-4 {
      padding-right: 1rem !important;
    }
    .pl-4 {
      padding-left: 1rem !important;
    }
    .pt-4 {
      padding-top: 1rem !important;
    }
    .pb-4 {
      padding-bottom: 1rem !important;
    }
    .select-input {
      border-radius: 10px;
      box-shadow: 0px 2px 4px #0000001c;
    }

    .input div {
      border-radius: 10px;
      box-shadow: 0px 2px 4px #0000001c;
    }

    .input:focus,
    .select-input:focus {
      border-color: white !important;
      box-shadow: 0px 2px 4px #0000001c !important;
      border: 1px solid #ced4da;
    }

    .send-otp-input-filed {
      width: 100% !important;
      max-width: 40px;
      height: 40px;
      font-size: 24px;
      font-family: Roboto-Bold;
      border-radius: 5px;
      border: 1.5px solid #9e9e9e;
    }
    .send-otp-input-filed:focus {
      outline: none;
    }
    //buttons //

    .enRadioWithText {
      display: inline-flex;
      align-items: center;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border: 1px solid #000;

        @media screen and (max-width: 1366px) {
          height: 24px;
          width: 24px;
          top: -3px;
        }

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 4px;
          left: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;

          @media screen and (max-width: 1366px) {
            top: 3px;
            left: 3px;
          }
        }
      }

      &.small {
        .checkmark {
          height: 22px;
          width: 22px;

          &:after {
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
          }
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;

        &:checked + .checkmark {
          &:after {
            display: block;
            background: #ee3a23;
          }
        }
      }

      &:hover input + .checkmark {
        opacity: 0.5;
      }
    }
    .carousel-indicators {
      bottom: -44px !important;
    }

    .carousel .carousel-indicators button {
      border-radius: 50%;
      height: 8px;
      width: 8px;
    }

    .nestedjustify-between .css-o4b71y-MuiAccordionSummary-content {
      justify-content: space-between !important;
      margin: 4px 0;
    }
    .nestedjustify-between
      .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 4px 0;
    }

    //policy screen css////

    .policy-heading {
      h2 {
        margin: 30px 0px 20px 0px;
        padding-top: 20px;
      }
    }
    .policy-paragraph {
      p {
        font-size: 12px;
        margin: 0px 2px 20px -10px;
        padding: 5px 25px 0px 10px;
        font-weight: 600;
      }
    }

    .full-screem-image-menu {
      background-size: cover;
    }

    //////
    ///
    ///
    ///
    ///
    .shapedividers_com-3009 {
      overflow: hidden;
      position: relative;
    }
    .shapedividers_com-3009::before {
      content: "";
      font-family: "shape divider from ShapeDividers.com";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      top: -1px;
      z-index: 3;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: 102% 19px;
      background-position: 50% 0%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1143.58 27.83"><path d="M1143.58 1.7H0V0h1143.58ZM0 1.7h2.94c-.98 0-1.96.23-2.91.23zm1143.58 0v6.8a85.35 85.35 0 0 0-34.47-6.8Zm-105.96 26.08a103.1 103.1 0 0 0-61.39-26.14h126.42a102.87 102.87 0 0 0-65 26.17zm-137.32 0A103.1 103.1 0 0 0 838.9 1.64h122.56a104.75 104.75 0 0 0-61.12 26.14zm-137.32 0c-39.16-34.66-97.92-34.66-136.8 0a103.1 103.1 0 0 0-61.37-26.14h259.34a104.75 104.75 0 0 0-61.12 26.14zm-274.1 0a102.98 102.98 0 0 0-65-26.09h126.19a104.48 104.48 0 0 0-61.12 26.14zm-137.06 0A102.98 102.98 0 0 0 286.83 1.7h129.8c-23.27.8-46.4 9.8-64.7 26.14zm-137.06 0A102.98 102.98 0 0 0 149.78 1.7h129.8a102.66 102.66 0 0 0-64.71 26.14zm-137.05 0A101.68 101.68 0 0 0 15.05 1.7H142.5c-23.28.8-46.4 9.8-64.71 26.14z" fill="%23e9e7fd"/></svg>');
    }

    @media (min-width: 768px) {
      .shapedividers_com-3009::before {
        background-size: 100% 59px;
        background-position: 50% 0%;
      }
    }

    @media (min-width: 1025px) {
      .shapedividers_com-3009::before {
        bottom: -0.1vw;
        left: -0.1vw;
        right: -0.1vw;
        top: -0.1vw;
        background-size: 100% 59px;
        background-position: 50% 0%;
      }
    }
    @media (min-width: 2100px) {
      .shapedividers_com-3009::before {
        background-size: 100% calc(2vw + 59px);
      }
    }

    //vide0 menu css //

    .video-player,
    .thumbnail-image {
      height: 90vh;
      width: 90vw;
      max-width: 480px;
      background-color: rgb(0 0 0 / 88%);
      margin: auto 1rem;
      border-radius: 20px;
      object-fit: cover;
      -webkit-playsinline: auto; /* iOS-specific property */
    }

    .video-cards {
      position: relative;
    }

    video {
      width: 100%;
      height: 100%;
      scroll-snap-align: start;
      /* position: relative; */
    }

    .video-container {
      position: relative;
      // height: 80vh;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      background-color: white;
      overflow: scroll;
      scroll-snap-type: y mandatory;
      scrollbar-width: none;
    }

    /* for chrome and opera */
    .video-container::-webkit-scrollbar {
      display: none;
    }

    .video-text {
      position: absolute;
      bottom: 13%;
      color: #fff;
      // display: flex;
      // margin-bottom: 20px;
      bottom: 4px;
      left: 16px;
      background: rgba(0, 0, 0, 0.1);
      width: 90vw;
      border-radius: 20px;
      max-width: 480px;
    }

    .video-text > h3 {
      margin-left: 20px;
    }

    .footer-buttons {
      position: absolute;
      bottom: 13%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }

    .flex-box {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      .video-text {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
    }
  }

  &:hover input + .checkmark {
    opacity: 0.5;
  }
}
.carousel-indicators {
  bottom: -44px !important;
}

.carousel .carousel-indicators button {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.nestedjustify-between .css-o4b71y-MuiAccordionSummary-content {
  justify-content: space-between !important;
  margin: 4px 0;
}
.nestedjustify-between .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 4px 0;
}

//policy screen css////

.policy-heading {
  h2 {
    margin: 30px 0px 20px 0px;
    padding-top: 20px;
  }
}
.policy-paragraph {
  p {
    font-size: 12px;
    margin: 0px 2px 20px -10px;
    padding: 5px 25px 0px 10px;
    font-weight: 600;
  }
}

.full-screem-image-menu {
  background-size: cover;
}

//////
///
///
///
///
.shapedividers_com-3009 {
  overflow: hidden;
  position: relative;
}
.shapedividers_com-3009::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 102% 19px;
  background-position: 50% 0%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1143.58 27.83"><path d="M1143.58 1.7H0V0h1143.58ZM0 1.7h2.94c-.98 0-1.96.23-2.91.23zm1143.58 0v6.8a85.35 85.35 0 0 0-34.47-6.8Zm-105.96 26.08a103.1 103.1 0 0 0-61.39-26.14h126.42a102.87 102.87 0 0 0-65 26.17zm-137.32 0A103.1 103.1 0 0 0 838.9 1.64h122.56a104.75 104.75 0 0 0-61.12 26.14zm-137.32 0c-39.16-34.66-97.92-34.66-136.8 0a103.1 103.1 0 0 0-61.37-26.14h259.34a104.75 104.75 0 0 0-61.12 26.14zm-274.1 0a102.98 102.98 0 0 0-65-26.09h126.19a104.48 104.48 0 0 0-61.12 26.14zm-137.06 0A102.98 102.98 0 0 0 286.83 1.7h129.8c-23.27.8-46.4 9.8-64.7 26.14zm-137.06 0A102.98 102.98 0 0 0 149.78 1.7h129.8a102.66 102.66 0 0 0-64.71 26.14zm-137.05 0A101.68 101.68 0 0 0 15.05 1.7H142.5c-23.28.8-46.4 9.8-64.71 26.14z" fill="%23e9e7fd"/></svg>');
}

@media (min-width: 768px) {
  .shapedividers_com-3009::before {
    background-size: 100% 59px;
    background-position: 50% 0%;
  }
}

@media (min-width: 1025px) {
  .shapedividers_com-3009::before {
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 100% 59px;
    background-position: 50% 0%;
  }
}
@media (min-width: 2100px) {
  .shapedividers_com-3009::before {
    background-size: 100% calc(2vw + 59px);
  }
}

//vide0 menu css //

.video-player,
.thumbnail-image {
  height: 90vh;
  width: 90vw;
  max-width: 480px;
  background-color: rgb(0 0 0 / 88%);
  margin: auto 1rem;
  border-radius: 20px;
  object-fit: cover;
  -webkit-playsinline: auto; /* iOS-specific property */
}

.video-cards {
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  /* position: relative; */
}

.video-container {
  position: relative;
  // height: 80vh;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}

/* for chrome and opera */
.video-container::-webkit-scrollbar {
  display: none;
}

.video-text {
  position: absolute;
  bottom: 13%;
  color: #fff;
  // display: flex;
  // margin-bottom: 20px;
  bottom: 4px;
  left: 16px;
  background: rgba(0, 0, 0, 0.1);
  width: 90vw;
  border-radius: 20px;
  max-width: 480px;
}

.video-text > h3 {
  margin-left: 20px;
}

.footer-buttons {
  position: absolute;
  bottom: 13%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.flex-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .video-text {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.cartbar {
  // position: fixed;
  // width: 100%;
  // bottom: 0;
  background-color: #fc5a47;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  color: whitesmoke;
  padding: 8px 12px;
  height: 58px;
}

.border-active {
  border: 1px solid #fc5a47;
}

.border-radius {
  border-radius: 8px;
}

.bg-light-indigo {
  background-color: #c8c8f826;
}

//////form feilds stylings //////
////////////////////////////////

.customTexfeild {
  border-radius: 16px;
  box-shadow: 0px 3px 13px #2121210f;
  border: 1px solid #ffffff !important;
}

.bg-m2 {
  background-color: #fee8f2;
}

.cartbar {
  position: fixed;
  width: 80%;
  bottom: 0px;
  background-color: #fc5a47;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  color: whitesmoke;
  padding: 8px 12px;
  height: 58px;
  z-index: 1000;
}
@media (max-width: 950px) {
  .cartbar {
    bottom: 50px;
    width: 100%;
    border-radius: 0;
  }
}
.border-active {
  border: 1px solid #fc5a47;
}

.border-radius {
  border-radius: 8px;
}

.bg-light-indigo {
  background-color: #c8c8f826;
}
.payment_screen {
  border-radius: 12px;
  padding: 10px 15px;
}
.order_bg {
  background-color: #fcf7d0;
  border-radius: 19px;
  padding: 10px 15px;
}
.eperience_name {
  padding: 10px 15px;
  background-color: #f2d89b;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.topDateTimeBox {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 13px;
  width: "100%";
  background-color: #fff7ec;
  border-radius: 12px;
  box-shadow: 0 1px 2.22px rgba(0, 0, 0, 0.22);
}

.divider_line {
  border-width: 1px;
  border-color: #e0e0e0;
  width: "100%";
  padding: 10px 15px;
}
.reuse_count_price_edit {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.shareAndHeart {
  width: 25px;
  height: 25px;
  border-radius: 12px;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  box-shadow: 0 1px 2.22px rgba(0, 0, 0, 0.22);
}

.direction_row {
  flex-direction: row;
}
.paymentSummary {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.amealio-checkbox {
  input {
    background-color: #fc5a47;
  }
}

.grey-round-border {
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  padding: 8px;
}

/* Sidebar - make it fixed */
.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Content area - adjust when sidebar is present */
.main-content {
  margin-left: 240px; /* Sidebar width */
  padding: 10px;
  flex: 1;
}

/* For small screens (tablet and mobile) */
@media (max-width: 1024px) {
  .main-content {
    margin-left: 0; /* Remove margin on smaller screens */
    padding-bottom: 60px; /* Add bottom padding for the BottomNavBar */
  }
}

/* Fixed bottom navigation bar */
.bottom-nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
}

.home-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-row {
  flex: 1;
}

.content-column {
  padding: 20px;
}

.main-content {
  padding: 10px;
}

.search-bar-container.desktop {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.search-bar-container.mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.restaurant-card-container.desktop {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.restaurant-card-container.mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.restaurant-card-wrapper {
  max-width: 100%;
}

@media (max-width: 1024px) {
  .content-column {
    padding-bottom: 60px;
  }
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f9f9f9;
}
