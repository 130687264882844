.restaurant-details {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0 auto;
  max-width: 1200px;
}

.header-banner {
  background-size: cover;
  background-position: center;
  border-radius: 0px;
  padding: 40px;
  color: white;
  height: 40px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left h2 {
  margin: 0px;
  font-size: 1.8rem;
}

.header-left p {
  margin: 5px 0 0;
  color: red;
}

.header-left p1 {
  margin: 5px 0 0;
  color: black;
}

.header-right{
  margin-right: 500px;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 5px 10px;
  margin-right: -100px;
  margin-top: -10px;
}

.rating .star {
  color: rgb(21, 225, 48);
  margin-right: 5px;
}

.event-info-banner {
  background-size: cover;
  background-position: center;
  border-radius: 0px;
  padding: 0px;
  margin-top: 0px;
  height: 145px;
}

.event-content {
  display: flex;
  justify-content:space-around;
}


.event-time {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; 
  background-color: #F0F0FF; 
  padding: -20px;
  border-radius: 8px;
  margin-left: -70px;
  margin-top: -30px;
}

.event-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #a3a3a3; 
 
}

.event-item img {
  width: 30px;
  height: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.event-item span {
  white-space: nowrap; /* Prevent text from wrapping */
}

.divider {
  width: 1px;
  height: 60px;
  background-color: #d3d3d3; /* Divider color */
}


.icon {
  width: 30px;
  height: 25px;
}

.action-button {
  border: none;
 background-color: white; 
 margin-left:700px;
 margin-bottom: 50px;
 margin-top: -30px;
 border-radius: 8px;
 
}

.action-button img {
  width: 50px;
  height: 50px;
  margin-top: -30px;
}

.call-icon{
  border: none;
  background-color: white; 
  margin-right: 0px;
}

.call-icon img {
  width: 40px;
  height: 40px;
}

.share-icon{
  border: none;
  background-color: white; 
  margin-right: 90px;
}

.share-icon img{
  width: 40px;
  height: 40px;
}

.qricon{
border: none; 
 margin-right: -50px;  
 margin-top: 50px;
}

.order-reference {
  margin-top: 20px;
  text-align: center;
}

.order-reference h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.order-reference p {
  font-size: 1rem;
}

.image-section {
  margin-top: -200px;
  text-align: center; 
  width: 100%;
}



.event-image {
  max-width: 75%;
  border-radius: 10px;
  height: auto;
  margin-top: -20px;
  width: 1500px;
}

.imagesection {
  margin-top: 120px;
  text-align: center; 
  width: 100%;
}
.eventimage {
  max-width: 75%;
  border-radius: 10px;
  height: auto;
  margin-top: -10px;
  width: 1500px;
}



.exppassdetails-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  margin-top: -20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .pack-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d0f0;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #FFFBFA;
  }
  
  .pack-info p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .pack-info p2 {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    
  }
  
  .price {
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  
  .includes {
    font-size: 17px; 
    font-weight: 700; 
    font-family: Arial, sans-serif; 
    color: #000; 
    line-height: 1.5; 
  }
  
  .experiencedetail{
    font-weight: bold; 
    font-size: 16px; 
    margin-top: 15px; 
    margin-bottom: 5px; 
  }
  
  .experiencedetail p{
    font-size: 16px; 
    margin-top: 15px; 
    margin-bottom: 5px; 
  }
  
  .experience-details {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .detail-row {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .icons {
    width: 20px;
    height: 20px;
    background-color: #D9D9D9;
    border-radius: 50%;
    position: absolute;
    left: 10;
    top: 0;
    margin-left: -2px;
  }
  
  .detail-row {
    position: relative; /* Required for the ::before pseudo-element */
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .detail-row::before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 7px; /* Align with the icon */
    z-index: -1; /* Place the line behind the content */
  }
  
  .detail-left {
    margin-left: 20px;
    flex-grow: 1; /* Ensures the left section takes available space */
  }
  
  .detail-left p {
    color: #3D3D3D;
    font-weight: 500;
    margin: 0;
  }
  
  .detail-left span {
    color: #D9D9D9; /* Updated 'p2' to 'span' for valid HTML */
    font-size: 0.9rem;
  }
  
  .detail-right {
    display: flex;
    flex-direction: column; /* Stacks the primary and secondary text */
    align-items: flex-end;
    justify-content: center; /* Centers content vertically */
    font-weight: bold;
    color: #3D3D3D;
    text-align: right;
    margin-right: 400px;
  }
  
  .detail-right p {
    font-family: sans-serif;
    font-weight: bold;
    color: #3D3D3D;
    text-align: right;
    margin: 0;
  }
  
  .detail-right span {
    color: #D9D9D9; 
    font-size: 0.9rem;
  }
  
  .right-text {
    margin-left: auto;
    text-align: right;
    font-size: 16px; 
    margin-top: -40px;
    font-weight: bold;
  }
  
  
    .footer-buttons {
      display: flex;
      justify-content: center;
      gap: 20px; /* Space between buttons */
      margin-top: 20px;
      margin-bottom: -1050px;
      margin-left: -550px;
    }
    
    .footer-buttons button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: 1px solid #EE3A23;
      border-radius: 8px;
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;
    }
    
    .footer-buttons button img {
      width: 20px;
      height: 20px;
      margin-right: 8px; /* Space between icon and text */
    }

    .exppassdetails-container::after {
      display: block;
      clear: both;
    }
  
  .footer-buttons button:hover {
    background-color: #f0f0f0;
  }
  
  @media (max-width: 768px) {
    .restaurant-details {
      padding: 5px;
      max-width: 100%;
      text-align: center;
    }
  
    .header-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-left: 0px;
    }
  
    .header-left h2 {
      font-size: 1.5rem;
      margin-bottom: 5px;
      font-size: 18px;
      text-align: left;
      margin-left:-90px;
    }
  
    .header-left p {
      margin: 5px 0;
    }
  
    .rating {
     display: none  ;
    }
    .experience-id{
      display: none;
    }

    .call-icon{
      border: none;
      background-color: white; 
      margin-right: -180px;
      margin-top: -35px;
    }
    
    .call-icon img {
      width: 30px;
      height: 30px;
    }
    
    .share-icon{
      border: none;
      background-color: white; 
      margin-right: -270px;
      margin-top: -30px;
    }
    
    .share-icon img{
      width: 30px;
      height: 30px;
    }


    .header-banner {
      background-size: cover;
      background-position: center;
      border-radius: 0px;
      padding: 30px;
      color: white;
      height: -10px;
    }
  
    .event-info-banner {
      padding: 10px;
      margin-top: 0px;
      height: 45px;
      gap: 0px;
    }
  
    .event-time {
      margin: 10px auto;
      gap: 10px;
      padding: 10px;
      border-radius: 8px;
      width: 50%;
      margin-top: -10px;
      height: 0px;
      margin-left: 8px;
    }

    .image-section {
      margin-top:  -350px;
      text-align: center; 
      width: 120%;
      margin-bottom: -200px;
      margin-left: -30px;
    }

    .event-item span {
      white-space: nowrap; 
    }
  
    .event-item img {
      width: 20px;
      height: 20px;
    }
  
    .imagesection {
      margin-top: 200px;
      text-align: center; 
      width: 120%;
    }
    .eventimage {
      max-width: 75%;
      border-radius: 10px;
      height: auto;
      margin-top: -10px;
      width: 1800px;
    }
    .qricon {
      margin: 10px auto;
      text-align: center;
    }
  
    .footer-buttons {
      flex-wrap: nowrap;
      gap: 10px;
      margin-top: 20px;
      margin-bottom: -450px;
      margin-left: -30px;

    }

    .experience-details { 
      margin-bottom: 20px;
    }
    
    h3 {
      display: none;
    }

    .detail-row {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      margin-top: 20px;
    }
    
    .icons {
      width: 20px;
      height: 20px;
      background-color: #ccc;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
    }
    
    .detail-row::before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: black;
      top: 0;
      bottom: 0;
      left: 7px; 
      z-index: -1; 
    }
    
    .detail-left {
      margin-left: 20px;
      flex-grow: 1;
    }
  
    .detail-left p1{
      color: gray;
    }
    
    .detail-right {
      display: flex; 
      align-items: center;
      justify-content: center; 
      font-weight: bold;
      color: #555;
      text-align: center; 
      height: 100%; 
      margin-right: 8px;
    }
  
    .footer-buttons button {
      width: 20%;
      justify-content: center;
      

      .exppassdetails-container {
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 20px;
        margin-top: 0px;
        }
    }
  }
  
  
  
