.chat-box-container {
    width: 600px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .restaurant-info h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .request-link {
    color: #007bff;
    font-size: 0.9em;
    text-decoration: none;
  }
  
  .request-link:hover {
    text-decoration: underline;
  }
  
  .call-icon {
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .chat-date {
    font-size: 0.9em;
    color: #888;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .chat-options {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .chat-link {
    color: #007bff;
    font-size: 0.9em;
    text-decoration: none;
  }
  
  .chat-link:hover {
    text-decoration: underline;
  }
  
  .chat-content {
    height: 300px;
    overflow-y: auto;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .message {
    margin-bottom: 15px;
  }
  
  .message-content {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 8px;
  }
  
  .message.user .message-content {
    background: #d0f0c0;
  }
  
  .message.vendor .message-content {
    background: #f1f1f1;
  }
  
  .message-time {
    font-size: 0.8em;
    color: #555;
    margin-top: 5px;
    text-align: right;
  }
  
  .chat-input-container {
    display: flex;
    align-items: center;
  }
  
  .chat-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .send-button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    background: #ff6347;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.5em;
  }
  
  .send-button:hover {
    opacity: 0.8;
  }
  