/* body {
    font-family: mulish;
    margin: 0;
    padding: 0;
  }
  
  
  .card {
    padding: 20px;
    width: 1332px;
    height: 750px;
    gap: 24px;
   
  }
  
  .card-header {
    border: none;
    width: 1132px;
    height: 407px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
  .restaurant-info {
    text-align: center;
    height: 407px;
    width: 349px;
    gap:8px;
    border-radius: 16px;
    background-color: #FBFBFB;
    border: 1px solid #EFEFEF;
    
  }
  .QRinfo{
    width: 241px;
height:375px;
gap: 16px;
opacity: 0px;
margin: 20px 0px 0px 45px;

  }
  .restaurant-info h2{
font-size: 18px;
font-weight: 600;
line-height: 23.4px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  
  .qr-code img {
    width: 241px;
    height: 240px;
    gap: 0px;
    opacity: 0px;
    
  }
  
  .customer-info {
    margin-top: 10px;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  
  .customer-info .status {
    width: 150px;
    height: 37px;
    display: inline-block;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px; 
    color: #fff;
    background-color: #4CAF50;
  }
  .waitlist{
    display: flex;
    flex-wrap: wrap;
    width:759px;
height: 102px;
padding: 16px 0px 0px 0px;
margin-left: 24px;
gap: 1px;
border-radius: 16px;
border:1px solid #EFEFEF;
opacity: 0px;
background-color: #FBFBFB;
padding-left:10px ;

  }
  .wl-AK{
    display: inline-flex;
    width: 281px;
height: 23px;
margin-left:10px ;
gap: 20px;
opacity: 0px;
font-weight: 600;
font-size: 18px;
line-height: 23.4px;

  }
 .pp-1{
    display: inline-flex;
    flex-direction: row;
    margin: 40px 0px 0px 10px;
    width: 727px;
height: 23px;
opacity: 0px;
gap:80px;
align-items: flex-end;
 }
 .p-1{
    display: inline-flex;
    flex-direction: row;
 }
 #p1 {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px; 
    color: #a2a1a1;
}
#p2{
font-size: 18px;
font-weight: 600;
line-height: 23px;
}
.request-info{
    width: 759px;
    height: 281px;
    border-radius: 16px;
    background-color:#FBFBFB;
    border: 1px solid #EFEFEF;
    margin: 24px 0px 0px 24px;
    display: flex;
    flex-wrap: wrap;
}
#ppm{
    width:242.33px;
height: 50px;
opacity: 0px;
column-gap: 2px;
margin: 20px 0px 0px 10px;
gap: 0px;
padding-left: 10px;
}
#p4{
    margin: 0px 0px 0px 0px;
    color: #8A8A8A;
    font-weight: 600;
    margin-top: -15px;
}
  
  .terms {
    margin-top: 20px;
    width: 1132px;
height:304px;
top: 628px;
left: 284px;
gap: 16px;
opacity: 0px;

  }
  
  .terms h3 {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 24px ;
    line-height: 36px;

  }
  
  .terms ol {
    padding-left: 20px;
  }
  
  .terms ol li {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    gap: 16px ;
  }
   */
   body {
    font-family: mulish;
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .card {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    gap: 24px;
  }
  
  .card-header {
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 20px;
  }
  
  .restaurant-info {
    text-align: center;
    width: 100%;
    max-width: 350px;
    gap: 8px;
    border-radius: 16px;
    background-color: #fbfbfb;
    border: 1px solid #efefef;
    padding: 10px;
  }
  
  .QRinfo {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .qr-code img {
    width: 100%;
    max-width: 240px;
    height: auto;
  }
  
  .customer-info {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  
  .customer-info .status {
    display: inline-block;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff;
    background-color: #4caf50;
  }
  
  .details {
    flex: 1;
    width: 100%;
  }
  
  .waitlist {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 16px;
    border: 1px solid #efefef;
    background-color: #fbfbfb;
  }
  
  .wl-AK {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .pp-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .p-1 {
    display: flex;
    flex-direction: row;
  }
  
  #p1 {
    font-weight: 800;
    font-size: 14px;
    color: #a2a1a1;
  }
  
  #p2 {
    font-size: 14px;
    font-weight: 600;
  }
  
  .request-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 16px;
    background-color: #fbfbfb;
    border: 1px solid #efefef;
    padding: 10px;
  }
  
  #ppm {
    width: calc(33% - 20px);
    margin: 10px;
  }
  
  #p4 {
    color: #8a8a8a;
    font-weight: 600;
  }
  
  .terms {
    margin-top: 20px;
    width: 100%;
  }
  
  .terms h3 {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 18px;
  }
  
  .terms ol {
    padding-left: 20px;
  }
  
  .terms ol li {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .card-header {
      flex-direction: column;
      align-items: center;
    }
  
    .restaurant-info,
    .details {
      width: 100%;
    }
  
    .request-info #ppm {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 480px) {
    .request-info #ppm {
      width: 100%;
    }
  
    .terms h3 {
      font-size: 16px;
    }
  
    .terms ol li {
      font-size: 12px;
    }
  }
  