.gps-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .gps-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .gps-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .gps-popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    width: 512px;
    height: 216px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .gps-popup-box h2 {
    margin-bottom: 10px;
    font-size:20px;
    font-weight: 800;
    line-height: 30px;
  }
  
  .gps-popup-box p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .gps-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .gps-no-button,
  .gps-enable-button {
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    width: 200px;
    height: 48px;
  }
  
  .gps-no-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .gps-enable-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .gps-no-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .gps-enable-button:hover {
    opacity: 0.8;
  }
  