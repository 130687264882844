/* Desktop Styles with rem Units */
.f8-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.5rem !important;
}

.f8-500 {
  font-weight: 500 !important;
  font-size: 0.5rem !important;
  font-family: $fontFamily !important;
}

.f8-700 {
  font-weight: 700 !important;
  font-size: 0.5rem !important;
  font-family: $fontFamily !important;
}

.f8-800 {
  font-weight: 800 !important;
  font-size: 0.5rem !important;
  font-family: $fontFamily !important;
}

.f9-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.5625rem !important;
}

.f9-500 {
  font-weight: 500 !important;
  font-size: 0.5625rem !important;
  font-family: $fontFamily !important;
}

.f9-700 {
  font-weight: 700 !important;
  font-size: 0.5625rem !important;
  font-family: $fontFamily !important;
}

.f9-800 {
  font-weight: 800 !important;
  font-size: 0.5625rem !important;
  font-family: $fontFamily !important;
}

.f10-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.625rem !important;
}

.f10-500 {
  font-weight: 500 !important;
  font-size: 0.625rem !important;
  font-family: $fontFamily !important;
}

.f10-700 {
  font-weight: 700 !important;
  font-size: 0.625rem !important;
  font-family: $fontFamily !important;
}

.f10-800 {
  font-weight: 800 !important;
  font-size: 0.625rem !important;
  font-family: $fontFamily !important;
}

.f11-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.6875rem !important;
}

.f11-500 {
  font-weight: 500 !important;
  font-size: 0.6875rem !important;
  font-family: $fontFamily !important;
}

.f11-700 {
  font-weight: 700 !important;
  font-size: 0.6875rem !important;
  font-family: $fontFamily !important;
}

.f11-800 {
  font-weight: 800 !important;
  font-size: 0.6875rem !important;
  font-family: $fontFamily !important;
}

.f12-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.75rem !important;
}

.f12-500 {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  font-family: $fontFamily !important;
}

.f12-700 {
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  font-family: $fontFamily !important;
}

.f12-800 {
  font-weight: 800 !important;
  font-size: 0.75rem !important;
  font-family: $fontFamily !important;
}

.f13-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.8125rem !important;
}

.f13-500 {
  font-weight: 500 !important;
  font-size: 0.8125rem !important;
  font-family: $fontFamily !important;
}

.f13-700 {
  font-weight: 700 !important;
  font-size: 0.8125rem !important;
  font-family: $fontFamily !important;
}

.f13-800 {
  font-weight: 800 !important;
  font-size: 0.8125rem !important;
  font-family: $fontFamily !important;
}

.f14-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.875rem !important;
}

.f14-500 {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  font-family: $fontFamily !important;
}

.f14-700 {
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  font-family: $fontFamily !important;
}

.f14-800 {
  font-weight: 800 !important;
  font-size: 0.875rem !important;
  font-family: $fontFamily !important;
}

.f15-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 0.9375rem !important;
}

.f15-500 {
  font-weight: 500 !important;
  font-size: 0.9375rem !important;
  font-family: $fontFamily !important;
}

.f15-700 {
  font-weight: 700 !important;
  font-size: 0.9375rem !important;
  font-family: $fontFamily !important;
}

.f15-800 {
  font-weight: 800 !important;
  font-size: 0.9375rem !important;
  font-family: $fontFamily !important;
}

.f16-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1rem !important;
}

.f16-500 {
  font-weight: 500 !important;
  font-size: 1rem !important;
  font-family: $fontFamily !important;
}

.f16-700 {
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: $fontFamily !important;
}

.f16-800 {
  font-weight: 800 !important;
  font-size: 1rem !important;
  font-family: $fontFamily !important;
}

.f18-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.125rem !important;
}

.f18-500 {
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  font-family: $fontFamily !important;
}

.f18-700 {
  font-weight: 700 !important;
  font-size: 1.125rem !important;
  font-family: $fontFamily !important;
}

.f18-800 {
  font-weight: 800 !important;
  font-size: 1.125rem !important;
  font-family: $fontFamily !important;
}

.f19-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.1875rem !important;
}

.f19-500 {
  font-weight: 500 !important;
  font-size: 1.1875rem !important;
  font-family: $fontFamily !important;
}

.f19-700 {
  font-weight: 700 !important;
  font-size: 1.1875rem !important;
  font-family: $fontFamily !important;
}

.f19-800 {
  font-weight: 800 !important;
  font-size: 1.1875rem !important;
  font-family: $fontFamily !important;
}

.f20-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.25rem !important;
}

.f20-500 {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  font-family: $fontFamily !important;
}

.f20-700 {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  font-family: $fontFamily !important;
}

.f20-800 {
  font-weight: 800 !important;
  font-size: 1.25rem !important;
  font-family: $fontFamily !important;
}

.f21-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.3125rem !important;
}

.f21-500 {
  font-weight: 500 !important;
  font-size: 1.3125rem !important;
  font-family: $fontFamily !important;
}

.f21-700 {
  font-weight: 700 !important;
  font-size: 1.3125rem !important;
  font-family: $fontFamily !important;
}

.f21-800 {
  font-weight: 800 !important;
  font-size: 1.3125rem !important;
  font-family: $fontFamily !important;
}

.f22-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.375rem !important;
}

.f22-500 {
  font-weight: 500 !important;
  font-size: 1.375rem !important;
  font-family: $fontFamily !important;
}

.f22-700 {
  font-weight: 700 !important;
  font-size: 1.375rem !important;
  font-family: $fontFamily !important;
}

.f22-800 {
  font-weight: 800 !important;
  font-size: 1.375rem !important;
  font-family: $fontFamily !important;
}

.f23-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.4375rem !important;
}

.f23-500 {
  font-weight: 500 !important;
  font-size: 1.4375rem !important;
  font-family: $fontFamily !important;
}

.f23-700 {
  font-weight: 700 !important;
  font-size: 1.4375rem !important;
  font-family: $fontFamily !important;
}

.f23-800 {
  font-weight: 800 !important;
  font-size: 1.4375rem !important;
  font-family: $fontFamily !important;
}

.f24-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.5rem !important;
}

.f24-500 {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  font-family: $fontFamily !important;
}

.f24-700 {
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  font-family: $fontFamily !important;
}

.f24-800 {
  font-weight: 800 !important;
  font-size: 1.5rem !important;
  font-family: $fontFamily !important;
}

.f28-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.75rem !important;
}

.f28-500 {
  font-weight: 500 !important;
  font-size: 1.75rem !important;
  font-family: $fontFamily !important;
}

.f28-700 {
  font-weight: 700 !important;
  font-size: 1.75rem !important;
  font-family: $fontFamily !important;
}

.f28-800 {
  font-weight: 800 !important;
  font-size: 1.75rem !important;
  font-family: $fontFamily !important;
}

.f30-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 1.875rem !important;
}

.f30-500 {
  font-weight: 500 !important;
  font-size: 1.875rem !important;
  font-family: $fontFamily !important;
}

.f30-700 {
  font-weight: 700 !important;
  font-size: 1.875rem !important;
  font-family: $fontFamily !important;
}

.f30-800 {
  font-weight: 800 !important;
  font-size: 1.875rem !important;
  font-family: $fontFamily !important;
}

.f32-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 2rem !important;
}

.f32-500 {
  font-weight: 500 !important;
  font-size: 2rem !important;
  font-family: $fontFamily !important;
}

.f32-700 {
  font-weight: 700 !important;
  font-size: 2rem !important;
  font-family: $fontFamily !important;
}

.f32-800 {
  font-weight: 800 !important;
  font-size: 2rem !important;
  font-family: $fontFamily !important;
}

.f36-400 {
  font-weight: 400 !important;
  font-family: $fontFamily !important;
  font-size: 2.25rem !important;
}

.f36-500 {
  font-weight: 500 !important;
  font-size: 2.25rem !important;
  font-family: $fontFamily !important;
}

.f36-700 {
  font-weight: 700 !important;
  font-size: 2.25rem !important;
  font-family: $fontFamily !important;
}

.f36-800 {
  font-weight: 800 !important;
  font-size: 2.25rem !important;
  font-family: $fontFamily !important;
}

/* Mobile Adjustments using Media Queries */
@media (max-width: 768px) {
  .f8-400,
  .f8-500,
  .f8-700,
  .f8-800 {
    font-size: 0.4rem !important;
  }

  .f9-400,
  .f9-500,
  .f9-700,
  .f9-800 {
    font-size: 0.5rem !important;
  }

  .f10-400,
  .f10-500,
  .f10-700,
  .f10-800 {
    font-size: 0.55rem !important;
  }

  .f11-400,
  .f11-500,
  .f11-700,
  .f11-800 {
    font-size: 0.6rem !important;
  }

  .f12-400,
  .f12-500,
  .f12-700,
  .f12-800 {
    font-size: 0.65rem !important;
  }

  .f13-400,
  .f13-500,
  .f13-700,
  .f13-800 {
    font-size: 0.7rem !important;
  }

  .f14-400,
  .f14-500,
  .f14-700,
  .f14-800 {
    font-size: 0.75rem !important;
  }

  .f15-400,
  .f15-500,
  .f15-700,
  .f15-800 {
    font-size: 0.8rem !important;
  }

  .f16-400,
  .f16-500,
  .f16-700,
  .f16-800 {
    font-size: 0.9rem !important;
  }

  .f18-400,
  .f18-500,
  .f18-700,
  .f18-800 {
    font-size: 1rem !important;
  }

  .f19-400,
  .f19-500,
  .f19-700,
  .f19-800 {
    font-size: 1.1rem !important;
  }

  .f20-400,
  .f20-500,
  .f20-700,
  .f20-800 {
    font-size: 1.15rem !important;
  }

  .f21-400,
  .f21-500,
  .f21-700,
  .f21-800 {
    font-size: 1.2rem !important;
  }

  .f22-400,
  .f22-500,
  .f22-700,
  .f22-800 {
    font-size: 1.25rem !important;
  }

  .f23-400,
  .f23-500,
  .f23-700,
  .f23-800 {
    font-size: 1.3rem !important;
  }

  .f24-400,
  .f24-500,
  .f24-700,
  .f24-800 {
    font-size: 1.35rem !important;
  }

  .f28-400,
  .f28-500,
  .f28-700,
  .f28-800 {
    font-size: 1.5rem !important;
  }

  .f30-400,
  .f30-500,
  .f30-700,
  .f30-800 {
    font-size: 1.6rem !important;
  }

  .f32-400,
  .f32-500,
  .f32-700,
  .f32-800 {
    font-size: 1.8rem !important;
  }

  .f36-400,
  .f36-500,
  .f36-700,
  .f36-800 {
    font-size: 2rem !important;
  }
}
