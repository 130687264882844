.profile-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
  border-radius: 0.375rem;
}

.logo {
  width: 6rem;
  margin-bottom: 1rem;
  margin-left: -500px;
}

.header-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 70px;
  margin-top: 15px;
}

.header-section h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

.header-section button {
  font-size: 0.875rem;
  color: #6b7280;
}

.skip-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  margin-left: 700px;
}

.profile-picture-instructions {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-left: -420px;
}

.profile-picture-subtext {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1.5rem;
  margin-left: -130px;
}

.default-profile {
  width: 7rem;
  height: 7rem;
  opacity: 0.5;
  margin-left: -500px;
}

.add-icon {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  bottom: 106px;
  right: 264px;
  transform: translateX(-4200%) translateY(-1250%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .logo {
    display: none;
  }

  .header-section {
    flex-direction: column;
    margin-top: -70px;
    margin-right: 50px;
    margin-left: -150px;
    padding-bottom: 30px;
  }

  .profile-picture-wrapper {
    width: 120px;
    height: 120px;
    margin-left: 10px;
  }

  .profile-picture-instructions {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    margin-left: -150px;
    text-align: left;
  }

  .profile-picture-subtext {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1.5rem;
    margin-left: -30px;
    text-align: left;
  }

  .default-profile {
    width: 7rem;
    height: 7rem;
    opacity: 0.5;
    margin-left: -200px;
  }

  .add-icon {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    bottom: -28px;
    right: 88px;
    transform: translateX(-750%) translateY(-1490%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.profile-setup-buttons {
  font-family: Arial, sans-serif;
  padding: 16px 0px;
  max-width: 400px;
}

.section {
  margin-bottom: 20px;
}

h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  margin-left: -250px;
}

.buttons-row {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.buttons-row2 {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.option-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-grow: 1;
  min-width: 100px;
  max-width: 120px;
  white-space: nowrap;
  gap: 5px;
}

.option-button.selected {
  border: 2px solid #61bcf5;
}

.option-button img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.option-button:hover {
  background-color: #e6e6e6;
}

.continue-button {
  width: 150%;
  padding: 15px;
  background-color: #ff4c4c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #e04343;
  width: 150%;
}

.image-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .profile-setup-buttons {
    font-family: Arial, sans-serif;
    padding: 15px 0px 15px 0;
    max-width: 400px;

    .buttons-row {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .buttons-row2 {
      display: flex;
      gap: 5px;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .option-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s;
      flex-grow: 1;
      min-width: 70px;
      max-width: 120px;
    }

    .option-button.selected {
      border: 2px solid #61bcf5;
    }

    .option-button img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .continue-button {
      width: 100%;
      padding: 15px;
      background-color: #ff4c4c;
      color: white;
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .continue-button:hover {
      background-color: #e04343;
      width: 150%;
    }
  }
}

.profile-image {
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 10px;
  position: sticky;
  margin-top: -80px;
}

.image-section {
  position: sticky;
  top: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .image-section {
    display: none;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-left: -170px;
  }
}

.center-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile {
  text-align: center;
}

.profile-setup-header {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.profile-setup-header p {
  font-weight: normal;
  font-size: 1em;
}

.profile-setup-container {
  max-width: 100%;
  padding: 20px;
}

.profile-setup-sections {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 100vh;
  overflow-y: scroll;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
}

.profile-setup-section {
  width: 100%;
  max-width: 500px;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 5px;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-left: 100px;
  gap: 2px;
}

.section-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.section-content p {
  font-size: 14px;
  margin: 5px 0;
}

.section-action {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 14px;
}

.completed-checkmark {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 18px;
  color: green;
}

.do-it-later-button {
  width: 100%;
  padding: 15px;
  background-color: #ff5733;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .profile-setup-container {
    padding: 15px;
  }

  .profile-setup-section {
    max-width: 100%;
    height: 120px;
    margin-left: 0;
    padding: 15px;
    gap: 5px;
  }

  .section-content h3 {
    font-size: 16px;
  }

  .section-content p {
    font-size: 12px;
  }

  .do-it-later-button {
    font-size: 16px;
    padding: 12px;

    .profile-image {
      display: none;
    }
  }
}

.save-button {
  position: sticky;
  width: 90%;
  padding: 15px;
  margin: 200px auto;
  background-color: grey;
  color: #ffffff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.save-button.active {
  background-color: #ff6347;
}

.save-button:disabled {
  cursor: not-allowed;
}

.dietary-preferences-page {
  padding: 20px;
}

.preferences {
  padding: 20px;
}

.image-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preferences-image {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.option {
  border: 1px solid #ff6347;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  color: #ff6347;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.option.selected {
  background-color: #ff6347;
  color: white;
}

.plus {
  font-weight: bold;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.search-icon {
  width: 20px; /* Adjust size as needed */
  margin-right: 10px;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 5px;
}

@media (max-width: 768px) {
  .profile-image {
    display: none;
  }
}

.manual-add-container {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.manual-add-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;
}

.manual-add-input::placeholder {
  color: #aaa;
}

.manual-add-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.pencil-icon {
  width: 16px;
  height: 16px;
}

.social-preferences-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.preference-section {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.preference-image {
  width: 50px;
  height: auto;
}

h2 {
  font-size: 18px;
  margin-top: 10px;
}
