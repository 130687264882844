.time-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .time-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .time-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .time-popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    width: 462px;
    height: 403px;
    gap:8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .time-popup-box h2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
  }
  
  .time-selection-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .time-option-button {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .time-option-selected {
    background-color: #EE3A2333;
    color: #ff6347;
    border: 1px solid #ff6347;
  }
  
  .time-option-button:hover {
    background-color: #ff6347;
    color: #fff;
    height: 45px;
    width: 200px;

  }
  
  .time-popup-buttons {
    display: flex;
    justify-content: space-between;
    
  }
  
  .time-cancel-button,
  .time-continue-button {
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    width: 200px;
    height: 48px;

  }
  
  .time-cancel-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .time-continue-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .time-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .time-continue-button:hover {
    opacity: 0.8;
  }
  