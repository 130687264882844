/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 463px;
  height: 188px;
  gap: 8px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin: 0;
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.view-faqs {
  font-size: 0.8em;
  color: #007bff;
  text-decoration: none;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.no-button,
.yes-button {
  border: none;
  padding: 10px;
  width: 100px;
  font-size: 1em;
  cursor: pointer;
}

.no-button {
  background: #fff;
  color: #f44336;
  border: 1px solid #f44336;
  border-radius: 4px;
}

.yes-button {
  background: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
}
