.seating {
  .top-title {
    box-shadow: rgb(0 0 0 / 33%) 0px 0px 15px 0px;
  }
}

.paxCard {
  background: transparent url("../images/IconsandImages/Rectangle\ 139653.png")
    0% 0% no-repeat padding-box;
  border: 0.44999998807907104px solid #927de8;
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.paxButton {
  background-color: #ffffff;
  border: 1px solid #927de8;
  border-radius: 10px;
  // width: fit-content !important;
  min-height: 28px;
  max-width: 75px;
}
.button {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff;
  border-radius: 11px;
  // width: fit-content;
  // padding: 5px 25px 5px 25px;
}

.select-button {
  border: 1px solid #7ca2e3;
}

.waitlist-card {
  // margin: 5px 10px 30px 10px;
  padding: 10px;
  border: 0.5px solid #b5b3b359;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
}

// In your CSS file
.otp-input {
  display: flex;
  justify-content: space-between;
}

.otp-input__box {
  width: 100%;
  min-height: 45px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 24px;
  text-align: center;
}

.otp-input__box:focus {
  outline: none;
  border-color: #0077ff;
  box-shadow: 0 0 3px 2px rgba(0, 119, 255, 0.3);
}
.penIconButton {
  .MuiPickersToolbar-penIconButton {
    display: none;
  }
}
