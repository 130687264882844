.eta-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .eta-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .eta-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .eta-popup-box {
    background: #fff;
    border-radius: 24px; 
    padding: 20px;
    width: 512px;
    height: 266px;
    gap:8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    padding:24px;
  }
  
  .eta-popup-box h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
  }
  
  .eta-popup-box p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .eta-form-group {
    margin-bottom: 20px;
  }
  
  .eta-input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .eta-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .eta-cancel-button,
  .eta-continue-button {
    border: none;
    padding: 10px 20px;
     width:224px;
     height: 48px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .eta-cancel-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .eta-continue-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .eta-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .eta-continue-button:hover {
    opacity: 0.8;
  }
  