/* popup1 */

.open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    width: fixed 512px;
    height: 195px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    gap:8px;
  }
  
  .heading {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;

  }
  
  .popup-box p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    gap:8px;
  }
  
  .no-button,
  .yes-button {
    width:224px;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .no-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .yes-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .no-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .yes-button:hover {
    opacity: 0.8;
  }
  

  /* popup2 */
  .register-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .register-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .register-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .register-popup-box {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .register-popup-box h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  
  .register-popup-box p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .register-form-group {
    margin-bottom: 20px;
  }
  
  .register-phone-input {
    display: flex;
    margin-bottom: 10px;
  }
  
  .register-country-code {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    background-color: #f9f9f9;
  }
  
  .register-phone-number {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    flex-grow: 1;
  }
  
  .register-text-input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .register-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .register-no-button,
  .register-yes-button {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .register-no-button {
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .register-yes-button {
    background-color: #ff6347;
    color: #fff;
  }
  
  .register-no-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  
  .register-yes-button:hover {
    opacity: 0.8;
  }
  