.share-open-popup-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    margin: 20px;
  }
  
  .share-open-popup-button:hover {
    background-color: #0056b3;
  }
  
  .share-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .share-popup-box {
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    height: 334px;
    width: 463px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .share-popup-box h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
  
  .share-popup-box p {
    margin-bottom: 10px;
    font-size: 1em;
    color: #000000;
  }
  
  .share-options,
  .social-options {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .share-option,
  .social-option {
    text-align: center;
  }
  
  .share-option img,
  .social-option img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .share-option span {
    display: block;
    font-size: 0.9em;
    color: #666;
  }
  
  .share-popup-buttons {
    display: flex;
    justify-content: center;
  }
  
  .share-cancel-button {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    background-color: #fff;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  
  .share-cancel-button:hover {
    background-color: #ff6347;
    color: #fff;
  }
  